.menu {
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: auto;
}

.menuItem {
  list-style: none;
  // height: 80px;
  align-self: center;
  justify-self: center;
}

.menuItem + .menuItem {
  margin-left: 3rem;

  // @media only screen and (max-width: 1000px) {
  //   margin-left: 0;
  // }
}
