.menu {
  display: flex;
  flex-direction: column;
  margin-top: 3.2rem;
  // align-items: center;
  // text-align: center;
  // margin-left: auto;
}

.menuItem {
  list-style: none;
  margin-bottom: 3.2rem;
  // height: 80px;
  // align-self: center;
  // justify-self: center;
}

.menuItem + .menuItem {
  // margin-left: 3rem;

  // @media only screen and (max-width: 1000px) {
  //   margin-left: 0;
  // }
}
