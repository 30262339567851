.menu {
  display: flex;
  align-items: center;
  text-align: center;
}

.menuItem {
  list-style: none;
  // height: 80px;
  align-self: center;
  justify-self: center;
  button {
    border-radius: 0;
    padding-bottom: 10px;
    border-style: solid;
    border-color: transparent;
    border-bottom-width: 3px;
    &.active {
      border-color: #8d00ff;
      text-decoration: none;
      span {
        /* TODO Fix */
        /* color: ${Colors.primary}; */
        font-weight: 700;
        color: #8d00ff;
      }
    }
  }
}

.menuItem + .menuItem {
  margin-left: 3rem;

  // @media only screen and (max-width: 1000px) {
  //   margin-left: 0;
  // }
}
