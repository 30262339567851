.menu {
  display: flex;
  align-items: center;
  text-align: center;
}

.menuItem {
  list-style: none;
  // height: 80px;
  align-self: center;
  justify-self: center;
  a {
    border-radius: 0;
    padding-bottom: 8px;
    border-style: solid;
    border-color: transparent;
    border-bottom-width: 3px;
  }
  a:hover {
    text-decoration: none;
  }
  a.active:hover {
    text-decoration: none;
  }
  &.active {
    text-decoration: none;
    font-weight: 700;
    span {
      /* TODO Fix */
      /* color: ${Colors.primary}; */
      color: #8d00ff;
    }
  }
}

.menuItem + .menuItem {
  margin-left: 3rem;

  // @media only screen and (max-width: 1000px) {
  //   margin-left: 0;
  // }
}
