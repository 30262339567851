@for $i from 0 through 5 {
  .ml-#{$i},
  .mx-#{$i},
  .m-#{$i} {
    margin-left: calc(#{$i} * 0.5rem) !important;
  }

  .mr-#{$i},
  .mx-#{$i},
  .m-#{$i} {
    margin-right: calc(#{$i} * 0.5rem) !important;
  }

  .mt-#{$i},
  .my-#{$i},
  .m-#{$i} {
    margin-top: calc(#{$i} * 0.5rem) !important;
  }

  .mb-#{$i},
  .my-#{$i},
  .m-#{$i} {
    margin-bottom: calc(#{$i} * 0.5rem) !important;
  }

  // Padding ###########

  .pl-#{$i},
  .px-#{$i},
  .p-#{$i} {
    padding-left: calc(#{$i} * 0.5rem) !important;
  }

  .pr-#{$i},
  .px-#{$i},
  .p-#{$i} {
    padding-right: calc(#{$i} * 0.5rem) !important;
  }

  .pt-#{$i},
  .py-#{$i},
  .p-#{$i} {
    padding-top: calc(#{$i} * 0.5rem) !important;
  }

  .pb-#{$i},
  .py-#{$i},
  .p-#{$i} {
    padding-bottom: calc(#{$i} * 0.5rem) !important;
  }
}
